<template>
  <div class="employment__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      用工需求管理
    </div>
    <a-modal :visible="visible" title="查看详情" @ok="handleOk" @cancel="onCancel" width="1000px">
      <a-row>
        <a-col :span="12">
          <p>项目部：{{ row.projectDepartmentName }}</p>
        </a-col>
        <a-col :span="12">
          <p>项目名称：{{ row.projectName }}</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <p>子项目名称：{{ row.wbProject }}</p>
        </a-col>
        <a-col :span="12">
          <p>报酬：{{ row.wbProjectSalary }}</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <p>子项目描述：{{ row.wbProjectDes }}</p>
        </a-col>
        <a-col :span="12">
          <p>工种：{{ row.positionName }}</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <p>基本技能：{{ row.baseSkill }}</p>
        </a-col>
        <a-col :span="12">
          <p>专业技能：{{ row.professionalSkill }}</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <p>项目经历：{{ workObj[row.experience] }}</p>
        </a-col>
        <a-col :span="12">
          <p>技能等级证：{{ levelObj[row.skillLevelCertificate] }}</p>
        </a-col>
      </a-row>
    </a-modal>
    <div class="content">
      <div style="margin-bottom: 24px">
        <a-row>
          <a-col :span="6">
            <div class="recruitUse_text">
              <a-row>
                <a-col :span="6">项目部:</a-col>
                <a-col :span="18">
                  <a-select
                    ref="select"
                    allowClear
                    :options="Departmentlist"
                    v-model="queryParams.projectDepartmentId"
                    placeholder="请选择项目部"
                    @change="changeDepartment"
                    style="width: 220px"
                  ></a-select>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="recruitUse_text">
              <a-row>
                <a-col :span="6">项目名称:</a-col>
                <a-col :span="18">
                  <a-select
                    allowClear
                    ref="select"
                    :options="Projectlist"
                    v-model="queryParams.projectId"
                    placeholder="请选择项目名称"
                    @change="changeProjectlist"
                    style="width: 220px"
                  ></a-select>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="recruitUse_text">
              <a-row>
                <a-col :span="6">子项目名称:</a-col>
                <a-col :span="18">
                  <a-select
                    ref="select"
                    v-model="queryParams.demandId"
                    :options="Positionlist"
                    placeholder="请选择子项目名称"
                    style="width: 200px"
                    allowClear
                  ></a-select>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button type @click="handleReset">重置</a-button>
            <a-button type="primary" @click="search">查询</a-button>
          </a-col>
        </a-row>
      </div>
      <div>
        <p-table
          ref="table"
          :sourceData="getDataApi"
          :columns="columns"
          rowKey="demandId"
          :scroll="{ y: 100 }"
          extraHeight="400"
          hideOnSinglePage
          download
          fileName="用工需求管理"
        >
          <template slot="headerLeft">
            <a-button type="primary" @click="handleOpenPage">发布项目承揽</a-button>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="operation_btn" @click="show(record)">查看详情</div>
            <div class="operation_btn" @click="stop(record)" v-if="record.status == 1">取消发布</div>
            <div class="operation_btn" v-else style="color: black">已取消</div>
          </template>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  queryAllWbProject,
  employmentEmployPage,
  employmentEmployStops
} from '@/api/recruitUse'

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    width: 60,
    customRender: (text, record, index) => index + 1
  },
  {
    title: '项目部',
    dataIndex: 'projectDepartmentName',
    download: true,
    key: 'projectDepartmentName'
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    download: true,
    key: 'projectName'
  },
  {
    title: '子项目名称',
    download: true,
    dataIndex: 'wbProject'
  },
  {
    title: '项目报价（元）',
    dataIndex: 'wbProjectSalary',
    download: true
  },
  {
    title: '发布人',
    dataIndex: 'createBy',
    download: true
  },
  {
    title: '发布时间',
    dataIndex: 'createTime',
    download: true
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  data () {
    return {
      workObj: ['不限', '30万以下', '30-60万', '100万及以上'],
      levelObj: {
        0: '无',
        1: '初级',
        2: '中级',
        3: '高级',
        '-1': '特殊工种证书'
      },
      row: '',
      delayTime: 100,
      autoExpandParent: true,
      visible: false,
      data: [],
      columns,
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      queryParams: {
        projectId: undefined,
        position: undefined,
        projectDepartmentId: undefined,
        demandId: undefined
      },
      list: [],
      obj: {},
      wbProject: '',
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status === 2
          }
        })
      }
    }
  },
  mounted () {
    // 获取项目部
    employmentEmployDepartment()
      .then((res) => {
        res.data.forEach((v) => {
          v.label = v.projectDepartment
          v.value = v.projectDepartmentId
        })
        this.Departmentlist = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    onCancel () {
      this.visible = false
      this.$set(this, 'row', '')
    },
    handleOpenPage () {
      window.open('/employment/workStaffing')
    },
    handleOk () {
      this.visible = false
      this.$set(this, 'row', '')
    },
    // getDataApi (params) {
    //   if (this.queryParams.projectDepartmentId) {
    //       return employmentEmployPage({
    //        ...params,
    //        // 项目部信息
    //        projectDepartmentId: this.queryParams.projectDepartmentId,
    //        // 项目名称信息
    //        projectId: this.queryParams.projectId,
    //        // 项目职位  demandId.label可以获取是设置了 labelInValue
    //        demandId: this.queryParams.demandId })
    //   }
    //   return Promise.resolve()
    // },
    getDataApi (params) {
      const params1 = {
        ...params,
        // 项目部信息
        projectDepartmentId: this.queryParams.projectDepartmentId,
        // 项目名称信息
        projectId: this.queryParams.projectId,
        // 子项目名称
        demandId: this.queryParams.demandId,
        flag: 2
      }
      return employmentEmployPage(params1)
    },
    search () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.$set(this, 'queryParams', { position: undefined, projectDepartmentId: undefined, demandId: undefined })
      this.Projectlist = []
      this.Positionlist = []
    },
    // handleGetData () {
    //   if (this.queryParams.demandId) {
    //     const params = {
    //       position: this.queryParams.demandId.label,
    //       pageSize: this.pagination.pageSize,
    //       pageIndex: this.pagination.current
    //     }
    //     employmentEmployPage(params).then(res => {
    //       this.tableData = res.data
    //     })
    //   } else {
    //     this.tableData = []
    //   }
    // },
    // 获取项目名称
    changeDepartment (val) {
      this.queryParams.projectId = undefined
      this.queryParams.demandId = undefined
      this.Projectlist = []
      this.Positionlist = []
      const params = {
        projectDepartmentId: val
      }
      val &&
        enterpriseEmploymentManagement(params).then((res) => {
          res.data.forEach((v) => {
            v.label = v.projectName
            v.value = v.projectId
          })
          this.Projectlist = res.data
        })
    },
    // 获取工种/专业
    changeProjectlist (val) {
      this.Positionlist = []
      this.queryParams.demandId = undefined
      const params = {
        projectId: val
      }
      val &&
        queryAllWbProject(params).then((res) => {
          res.data.forEach((v) => {
            v.label = v.wbProject
            v.value = v.demandId
          })
          this.Positionlist = res.data
        })
    },
    show (row) {
      this.visible = true
      this.row = row
    },
    stop (record) {
      const that = this
      const demandId = record.demandId
      this.$confirm({
        title: '取消发布',
        content: '是否确定要取消发布',
        onOk () {
          employmentEmployStops(demandId).then((res) => {
            that.$message.success('操作成功')
            that.$refs.table.refresh()
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');
.employment__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
  .item {
    display: flex;
    align-items: center;
    flex: 1;
    &:nth-of-type(2) {
      justify-content: center;
    }
    &:last-child {
      /*justify-content: flex-end;*/
    }
    .label {
      margin-right: 20px;
      width: 60px;
    }
  }
  .commission_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .top,
    .search_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      height: 31px;
    }
    .btn_box {
      display: flex;
      height: 31px;
      justify-content: flex-end;
      margin-bottom: 15px;
    }
  }
}
</style>
